import React from "react"
import "./mystef.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MailingListForm from "../components/mailingListForm"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <section className="section">
      <div className="container">
        <div class="columns is-centered">
          <div class="has-text-centered">
            <h1 class="title">
              Giving you the data you need to forecast your projects
            </h1>
          </div>
        </div>
      </div>
    </section>
    <section className="section is-paddingless">
      <div className="container">
        <div className="is-size-5">
          <p className="content">
            You work on client projects lasting from several months to several
            years. You've discussed scope creep, t-shirt sizes, sprints, and
            velocity. Some clients might even be on board with Agile.
      </p>
          <p className="content">But, ultimately, your clients want to know:</p>
          <p className="box has-text-centered has-background-danger has-text-white has-text-weight-bold">
            When will my project be done?!
      </p>
          <p className="content">
            You know the pain of trying to forecast the end date.
            And it never stops. Projects rarely go according to plan. You're always
            re-forecasting. You spend hours and hours re-forecasting.
      </p>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-flex">
            <div className="card">
              <div className="card-header">
                <div className="card-header-icon">
                  <i className="fa fa-frown"></i>
                </div>
                <div className="card-header-title">
                  Forecasting is HARD - like incredibly HARD
              </div>
              </div>
              <div className="card-content">
                <p>
                  Even in a fantasy world where you get all the info you need up
                  front (
                <span className="is-italic">
                    and you never get all you need up front
                </span>
                ), you're still guessing.
              </p>
                <p>
                  Is your team guessing effort or duration? Factoring in sickness,
                  internet outages, failed laptop upgrades?
              </p>
                <p className="has-text-weight-bold is-italic">
                  The complexity of forecasting is overwhelming.
              </p>
              </div>
            </div>
          </div>
          <div className="column is-flex">
            <div className="card">
              <div className="card-header">
                <div className="card-header-icon">
                  <i className="fa fa-calendar-check"></i>
                </div>
                <div className="card-header-title">
                  Forecasts always turn into commitments
              </div>
              </div>
              <div className="card-content">
                <p>
                  Regardless of the language in your contract / MSA / SOW, and no
                  matter how much you emphasize the forecast part, your clients
                  treats it as a commitment.
              </p>
                <p>
                  Your project team knows this. It's why they dread, even resist,
                  forecasting.
              </p>
                <p className="has-text-weight-bold is-italic">
                  Really - the complexity of forecasting is overwhelming!
              </p>
              </div>
            </div>
          </div>
          <div className="column is-flex">
            <div className="card">
              <div className="card-header">
                <div className="card-header-icon">
                  <i className="fa fa-calendar-times"></i>
                </div>
                <div className="card-header-title">
                  Planning is critical but plans are useless
              </div>
              </div>
              <div className="card-content">
                <p>
                  Your initial plan will be out of date within a few short weeks.
                You know the negative impact these{" "}
                  <span className="is-italic">"slips"</span> have on your client
                relationship.
              </p>
                <p>
                  Truth is you never stop forecasting. As long as your client
                  continues to pay you for work, you'll need to forecast that
                  work.
              </p>
                <p className="has-text-weight-bold is-italic">
                  Did we tell you the complexity of forecasting is overwhelming?
              </p>
              </div>
            </div>
          </div>
        </div>
        <p className="box has-text-centered has-background-info has-text-white has-text-weight-bold">
          My STEF gives you the system and data you need better manage expectations,
          saving you time and stress.
        </p>
      </div>
    </section>
    <MailingListForm />
  </Layout>
)

export default IndexPage
