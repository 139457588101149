import React from 'react';
import brandLogo from "../images/stef128.png"

const MailingListForm = () => (
    <section class="section is-paddingless">
        <div class="container">
            <div class="box">
                <div className="columns">
                    <div className="column">
                        <form className="form"
                            action="https://mysimpletime.us20.list-manage.com/subscribe/post?u=12da7461c9c8823ffdb09547a&amp;id=9eb2aa24ab"
                            method="post"
                            target="_blank">
                            <div className="subtitle">
                                If you're involved with forecasting projects, sign up for our mailing list to learn more!
                            </div>
                            <div className="field">
                                <p className="control has-icons-left">
                                    <label className="label" for="mce-FNAME">
                                        First Name <span class="asterisk">*</span>
                                    </label>
                                    <div className="control">
                                        <input className="input" required type="text" name="FNAME" id="mce-FNAME"
                                            placeholder="Your first name" />
                                        <span className="icon is-small is-left">
                                            <i className="fas fa-user"></i>
                                        </span>
                                    </div>
                                </p>
                            </div>
                            <div class="field">
                                <p className="control has-icons-left">
                                    <label className="label" for="mce-EMAIL">
                                        Email Address <span class="asterisk">*</span>
                                    </label>
                                    <div className="control">
                                        <input className="input" required type="email" name="EMAIL" id="mce-EMAIL"
                                            placeholder="Your email address" />
                                        <span className="icon is-small is-left">
                                            <i className="fas fa-envelope"></i>
                                        </span>
                                    </div>
                                </p>
                            </div>
                            <div className="field">
                                <div className="control">
                                    <input type="submit"
                                        className="button is-link"
                                        id="mc-embedded-subscribe"
                                        value="Sign me up and keep me in the loop!" />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="column is-one-quarter is-in-the-middle">
                        <figure className="image is-128x128">
                            <img
                                src={brandLogo}
                                alt="STEF - Simple Timeline Estimate Forecaster">
                            </img>
                        </figure>
                    </div>
                </div>
            </div>
        </div>
        <br/>
    </section>
);

export default MailingListForm
